html, body{
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

main {
  position: absolute;
  height: 100vh;
  width: 80%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 20px;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: url('./assets/grid.svg');
}

#menu{
  position: absolute;
  width: 20%;
  background-color: #088BCD;  
  top:0;
  color: #fff;
  bottom: 0;
  left: 0;
  z-index: 1;
}

#menu-bg-circle{
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
  width: 200%;
  opacity: 0.5;
}

#menu form{
  width:90%;
  margin: 0 auto;
  z-index: 2;
}

#menu form input{
  width:80%;
}
#menu form select{
  width:100%;
}

h2, h3{
  border-bottom: 1px solid white;
  line-height: 1.5;
}

#menu-header{
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.75);
  z-index:5;
}

#main-disclaimer{
  font-size: 14px;
  padding: 1rem;
  margin:1rem;

}


.disclaimer{
  font-size: 14px;
  padding: 0.5rem;
  margin: 0.5rem 0;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}

#fixed-info-popper, #display-info-popper{
  display: none;
  margin-left:10px !important;
  background-color: rgba(0, 0, 0, 0.75);
}

#display-info-popper{
  position: relative;
  top:-100px;
}


.input-area{
  display:flex;
  flex-flow:row nowrap;
  justify-content: space-evenly;

}

td{
  border: 1px solid white !important;
}

input, select{
  padding: 5px;
  margin-right: 5px;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
}

#module-selector{
  width: 100%;
}

#toggle-tabs{
  display:flex;
  justify-content: space-evenly;
  margin-top: 10px;

}

.tab{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid hsla(0,0%,100%,.7);
  border-right: 1px solid hwb(0 100% 0% / 0.7);
  border-bottom: 1px solid hsla(0,0%,100%,.7);
  border-top: 1px solid hsla(0,0%,100%,.7);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.tab.active{
  background-color: hsla(0,0%,100%,.7);
  color:black;
  border-bottom:1px solid hsla(0,0%,100%,.7);
}

#module-selector-content{
  padding:1rem;
  border-right:1px solid hsla(0,0%,100%,.7);
  border-left: 1px solid hsla(0,0%,100%,.7);
  border-bottom: 1px solid hsla(0,0%,100%,.7);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: hsla(0,0%,100%,.7);
}


#info{
  display:flex;
  position: relative;
  justify-content: space-evenly;
  background-color: #088BCD;  
  color:#fff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  z-index: 100;
}

#rendering{
  position:relative;
  height:100%;
  bottom:0;
  left:0;
  right:0;
}

#rendering>img{
  position:absolute;
  bottom:82px;
  transform-origin: bottom right;
}

.rendering-area, #fixed-wall{
  position:absolute;
  transform-origin: top left;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#fixed-wall{
  background-color: rgba(0,0,0,0.5);
}


.mod-wrapper{
  display:inline-flex;
  justify-content: center;
}

.module{
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.5);
}

.preset-buttons{
  display:flex;
  flex-flow: row wrap;
  margin-top: 10px;
}

.preset-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width:45%;
  margin: 0.5rem auto;
  height: 40px; 
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(0,0,0,0.15);
  transition: background-color 0.25s ease-in-out;
}

.preset-btn:hover{
  background-color: rgba(0,0,0,0.5);
}

#info-btn{
  width:25px;
  animation: pulse 1.5s infinite ease-in-out;
  cursor: pointer;
  transform: scale(1);
  color: #088BCD !important;
}


#info-btn:hover{
  animation: none;
  transform: scale(1.5);
}

@keyframes pulse{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.2);
  }
  100%{
    transform: scale(1);
  }
}

/*Contact Modal*/
#contact-modal{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display:none;
  justify-content: center;
  align-items: center;
  background-color: #088BCD;
  z-index: 1000;
}

#modal-content{
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
}

#modal-content > img{
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

#contact-form{
  padding: 1rem 3rem;
  display:flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#contact-form a{
  background-color: #088BCD;
  text-decoration: none;    
  color: #fff;
  border-radius:5px;
  padding:1rem;
}

table{
  width: 100%;
  border-collapse: collapse;
}

td{
  border: 1px solid black;
  padding: 1rem;
}

#close-contact-modal{
  position: absolute;
  top: 0;
  left: 0;
  margin: 30px;
  padding: 10px;
  font-size: large;
  cursor: pointer;
  color: #fff;
  background-color: #088BCD;
  border:1px solid white;
  border-radius: 5px;   
  height:50px;
  width:50px;
}

#close-contact-modal:hover{
  background-color: #fff;
  color: #088BCD;
}
